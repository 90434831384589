import React from 'react'

import Section from './Section'

const Footer = () => {
  const copyright = `2019 ${ process.env.REACT_APP_SITE_TITLE }. All rights reserved.`

  return (
    <Section
      id="footer"
      className="footer"
      title=" ">
      
      <footer>
        <div className="row">
            <p className="footer__copyright">&copy; {copyright}</p>
        </div>
      </footer>
      
    </Section>
  )
}

export default Footer