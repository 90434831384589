import React from 'react'

import enhanceWithClickOutside from 'react-click-outside'

import NavigationList from './NavigationList'

class NavigationMobile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isActive: false
    }
  }

  toggleActive = () => {
    this.setState({
      isActive: !this.state.isActive
    })
  }

  handleClickOutside() {
    this.state.isActive && this.toggleActive()
  }
  
  handleClick = () => {
    this.state.isActive && this.toggleActive()
	}

  render() {
    const isActive = this.state.isActive ? 'is-active' : ''
    const className = `navigation navigation-mobile ${ isActive }`

    return (
      <div className={className} >
        <div className="toggle togglenavigation" onClick={this.toggleActive}>
          <span className="tline-1"></span>
          <span className="tline-2"></span>
          <span className="tline-3"></span>
          <span className="tline-4"></span>
        </div>

        {
          this.state.isActive && 
            <NavigationList isMobile={true} handleClick={this.handleClick} />
        }
      </div>
    )
  }
}

export default enhanceWithClickOutside(NavigationMobile)