import React from 'react'

import Section from './Section'
import Service from './Service'

import service1 from '../images/services/shirt.svg'
import service2 from '../images/services/basket.svg'
import service3 from '../images/services/jewelery.svg'
import service4 from '../images/services/heart.svg'

const Services = () => {
  const facebook = `<a href=${ process.env.REACT_APP_LINK_FACEBOOK }>facebook</a>`
  const instagram = `<a href=${ process.env.REACT_APP_LINK_INSTAGRAM }>instagram</a>`

  const services = [
    {
      title: "Clothes",
      description: `We stitch the most beautiful outfits in the African fabrics. Our outfits come in all styles, patterns and designs. Have a look at our ${ instagram } and ${ facebook } page for the latest designs!`,
      image: service1
    }, {
      title: "Bags",
      description: "Our bags are made from a combination of recycled clothes and different pieces of African patterns. We strongly believe in minimizing waste for a healthier environment, hence our keen interest in giving it a second life with the slogan: Bin less, recycle more!",
      image: service2
    }, {
      title: "Jewelery",
      description: "There are plenty of reasons to choose handmade jewelry over machine made one. Check out our handcrafted jewelry selection for the very best unique African jewelery.",
      image: service3
    }, {
      title: "Order anything",
      description: `We don't limit your creativity, which means you can order whatever you feel like. You design, we create it. Have a look at our ${ instagram } and ${ facebook } social media accounts to get inspired!`,
      image: service4
    }
  ]

  const description = "We aspire to showcase the rich African fabrics in amazing contemporary designs. We provide fashionable tailor-made and hand-made products at affordable prices. It's important to us to reduce waste. That's why we recycle and re-use “throw-away“ clothes and give them a second life."

  return (
    <Section
      id="services"
      className="services"
      title="Our Services"
      description={description}>
      
      <div className="row row-small">
      {
        services.map((service, index) =>  <Service key={index} {...service} />)  
      }
      </div>
      
    </Section>
  )
}

export default Services