import React from 'react'

import GoogleMapReact from 'google-map-react'

import pin from '../images/pin.svg'

const AnyReactComponent = () => <div className="map__img">
  <img
    src={pin}
    alt="We are here!" />
  </div>

class MapContainer extends React.Component {
  static defaultProps = {
    center: {
      lat: 0.2985264,
      lng: 32.6180748
    },
    zoom: 16,
  }

  render() {
    return (
      <div className="map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={0.2985264}
            lng={32.6180748}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    )
  }
}



export default MapContainer 