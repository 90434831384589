import React from 'react'

import { Link } from 'react-scroll'

import Logo from './Logo'

class Intro extends React.Component {
  render() {
    let linkProps = {
      activeClass: 'is-active',
      spy: true,
      smooth: true,
      duration: 500,
      onSetInactive: this.handleSetActive,
      onClick: this.handleOnClick
    }

    return (
      <div id="home" className="intro">
        <Logo />
        <div className="intro__text">
          <h1>Zawadi ya Afrika</h1>
          <p>We create beautiful African outfits</p>
          <div className="btn__container btn-getintouch">
            <Link className="btn btn-full" to="contact" {...linkProps}>Get in touch</Link>
          </div>
          <div className="btn__container btn-showmemore">
            <Link className="btn btn-ghost" to="services" {...linkProps}>Show me more</Link>
          </div>
          <Link className="intro__downlink" to="services" {...linkProps}></Link>
        </div>
      </div>
    )
  }
}

export default Intro