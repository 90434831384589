import React from 'react'

import { Link } from 'react-scroll'
 
class NavigationList extends React.Component {
  handleSetActive = () => {
    if (typeof this.props.handleClick !== 'undefined') {
      this.props.handleClick()
    }
  }

  handleOnClick = () => {
    return false
  }
  
  render() {
    let { scrollLocation, isMobile } = this.props
    let linkProps = {
      activeClass: 'is-active',
      spy: true,
      smooth: true,
      duration: 500,
      onSetInactive: this.handleSetActive,
      onClick: this.handleOnClick
    }

    const cssScrollLocation = scrollLocation && scrollLocation
    const cssIsMobile = isMobile ? 'navigation-mobile' : ''
    const className=`navigation__list ${ cssScrollLocation } ${ cssIsMobile }`

    return (
      <ul className={className}>
        {
          isMobile && 
            <li><Link to="home" {...linkProps}>Home</Link></li>
        }
        <li><Link to="services" {...linkProps}>Services</Link></li>
        <li><Link to="showroom" {...linkProps}>Showroom</Link></li>
        <li><Link to="about" {...linkProps}>About</Link></li>
        <li><Link to="contact" {...linkProps}>Contact</Link></li>
      </ul>
    )
  }
}

export default NavigationList