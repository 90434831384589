import React from 'react'

import * as emailjs from 'emailjs-com'

class EmailForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      emailSent: false,
    }
  }

  isValid = () => {
    return this.state.name &&
      this.state.email &&
      this.state.subject &&
      this.state.message
  }

  updateName = event => {
    this.setState({name: event.target.value})
  }
  updateEmail = event => {
    this.setState({email: event.target.value})
  }
  updateSubject = event => {
    this.setState({subject: event.target.value})
  }
  updateMessage = event => {
    this.setState({message: event.target.value})
  }

  resetState = () => {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: ''
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    if (!this.isValid()) {
      return
    }

    this.sendFeedback(
      this.state.name,
      this.state.email,
      this.state.subject,
      this.state.message)
  }

  sendFeedback = (senderName, senderEmail, subject, message) => {
    var templateParams = {
      senderName,
      senderEmail,
      subject,
      message 
    }

    emailjs
      .send(
        'mailgun',
        'zawadiyaafrika_contact',
        templateParams,
        'user_rGj9KCtDq0TviwYCa8u11'
      )
      .then(res => {
        this.setState({ emailSent: true })
        this.resetState()
      })
      .catch(err => console.error('Failed to send message. Error: ', err))
  }

  render() {
    return (
      <form
        id="contact__form"
        action=""
        method="post"
        className="contact__form"
        onSubmit={this.handleSubmit}>

        <div className="row">
          <div className="col span-1-of-3">
              <label htmlFor="name">Name</label>
          </div>
          <div className="col span-2-of-3">
            <input
              id="name"  
              name="name"
              type="text"
              placeholder="Your name"
              data-rule="minlen:4"
              data-msg="Please enter at least 4 chars"
              value={this.state.name}
              required
              onChange={e => this.updateName(e)} />
          </div>
        </div>
        <div className="row">
          <div className="col span-1-of-3">
            <label htmlFor="email">Email address</label>
          </div>
          <div className="col span-2-of-3">
            <input
              id="email"  
              name="email"
              type="email"
              placeholder="Your email"
              data-rule="email"
              data-msg="Please enter a valid email"
              value={this.state.email}
              required
              onChange={e => this.updateEmail(e)} />
          </div>
        </div>
        <div className="row">
          <div className="col span-1-of-3">
            <label htmlFor="subject">Subject</label>
          </div>
          <div className="col span-2-of-3">
            <input                  
              id="subject"
              name="subject"
              type="text"
              placeholder="Subject"
              data-rule="minlen:4"
              data-msg="Please enter at least 8 chars of subject"
              value={this.state.subject}
              required
              onChange={e => this.updateSubject(e)} />
          </div>
        </div>
        <div className="row">
          <div className="col span-1-of-3">
            <label htmlFor="name">Message</label>
          </div>
          <div className="col span-2-of-3">
            <textarea
              id="message"
              name="message"
              rows="10"
              data-rule="required"
              data-msg="Please write something for us"
              placeholder="Your message"
              value={this.state.message}
              required
              onChange={e => this.updateMessage(e)} />
          </div>
        </div>
        <div className="row">
          <div className="col span-1-of-3">
            <label htmlFor="subject">&nbsp;</label>
          </div>
          <div className="col span-2-of-3">
            <input
              type="submit"
              value="Send Message"
              className="contact__form-input" />
          </div>
        </div> 

        {
          this.state.emailSent ?
            <div>Your message has been sent. Thank you!</div> : <div />
        }        

      </form>
    )
  }
}

export default EmailForm