import React from 'react'

import { Link } from 'react-scroll'

import logo from '../images/logo.svg'

const Logo = ({scrollLocation = null}) => {
  const alt = process.env.REACT_APP_SITE_TITLE
  const className= `logo ${ scrollLocation }`

  let linkProps = {
    activeClass: 'is-active',
    spy: true,
    smooth: true,
    duration: 500,
  }

  return (
    <div>
      <Link to="home" {...linkProps}>
        <img
          src={logo}
          alt={alt}
          className={className} />
      </Link>
    </div>
  )
}

export default Logo