import React from 'react'

import ReactWOW from 'react-wow'

const Service = ({title, description, image}) => {
  return (
    <div className="service col span-1-of-4">
      <ReactWOW animation='fadeInLeft' delay="0.2s">
        <div className="service__content">
          <div className="service__img">
            <img
              src={image}
              alt="" />
          </div>
          <div className="service__desc">
            <h3>{title}</h3>
            <p dangerouslySetInnerHTML={{__html: description}}></p>
          </div>
        </div>
      </ReactWOW>
    </div>
  )
}

export default Service