import React from 'react'

import SectionHeader from './SectionHeader'

const Section = ({id, className, title, description, children}) => {
  return (
    <section
      id={id}
      className={`section section--${ className }`}>
      
      <SectionHeader
        title={title}
        description={description} />

      {children}    
    </section>
  )
}

export default Section