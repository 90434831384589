import React from 'react'

import Logo from './Logo'
import Navigation from './Navigation'
import NavigationMobile from './NavigationMobile'

class Header extends React.Component {
  static typeScroll = 'scroll'
  
  state = {
    scrollLocation: 'is-top'
  }

  listenScrollEvent = e => {
    var scrollLocation = window.scrollY < 200 ? 'is-top' : 'is-scrolled'
    this.setState({scrollLocation})
  }

	componentDidMount() {
		window.addEventListener(Header.typeScroll, this.listenScrollEvent)
	}

	componentWillUnmount() {
		window.removeEventListener(Header.typeScroll, this.listenScrollEvent)
	}

  render() {
    return (
      <header>
        <nav>
          <Navigation scrollLocation={this.state.scrollLocation}>
            <Logo scrollLocation={this.state.scrollLocation} />
          </Navigation>
          <NavigationMobile scrollLocation={this.state.scrollLocation} />
        </nav>
      </header>
    )
  }
}

export default Header