import React from 'react'

import ReactWOW from 'react-wow'
  
const Card = ({title, name, image}) => {
  return (
    <div className="card">
      <ReactWOW animation='bounceInUp' delay="0.2s">
        <h3 className="card__name">{name}</h3>
        <img
          src={image}
          alt={name}
          className="card__img" />
        <p className="card__desc">{title}</p>
      </ReactWOW>
  </div>
  )
}

export default Card