import React from 'react'

import NavigationList from './NavigationList'
 
const Navigation = ({scrollLocation, children}) => {
  return (
    <div className={`navigation navigation-desktop ${ scrollLocation }`} >
      {children}
      <NavigationList scrollLocation={scrollLocation} />              
    </div>
  )
}

export default Navigation