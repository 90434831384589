import React from 'react'

import Section from './Section'
import EmailForm from './EmailForm'
import MapContainer from './MapContainer'

import facebook from '../images/social/facebook.svg'
import instagram from '../images/social/instagram.svg'

class Contact extends React.Component {
  address = () => {
    return (
      <address className="contact__address">
        <strong>Zawadi ya Afrika &mdash; SMC Limited</strong><br />
        Nakibirango Road<br />
        Tank Hill / Muyenga<br />
        Kampala<br />
        Uganda<br />
      </address>
    )
  }

  phone = () => {
    return (
      <address className="contact__phone">
        <strong>Phone:</strong> <a href="tel:+256772631238‬">+256 772 631 238‬</a>
      </address>
    )
  }

  email = () => {
    const m3 = "gmail.com"
    const m1 = "zawadiyaafrika"
    const m2 = "@"
    const email =  m1 + m2 + m3

    return (
      <address className="contact__email">
        <strong>Email:</strong> <a href={"mailto:" + email}>{email}</a>
      </address>
    )
  }

  social = () => {
    const link_facebook = process.env.REACT_APP_LINK_FACEBOOK
    const link_instagram = process.env.REACT_APP_LINK_INSTAGRAM

    return (
      <address className="contact__social">
        <strong>We're on social networks</strong><br />
        <ul className="company-social">
          <li className="social-facebook">
            <a
              href={link_facebook}
              target="_blank"
              rel="noopener noreferrer">
              <img src={facebook} alt="" />
            </a>
          </li>
          <li className="social-instagram">
            <a
              href={link_instagram}
              target="_blank"
              rel="noopener noreferrer">
              <img src={instagram} alt="" style={{fill:'white'}} />
            </a>
          </li>
        </ul>
      </address>
    )
  }

  render() {
    return (
      <Section
        id="contact"
        className="contact"
        title="Get in touch">

        <div className="row">
          <div className="contact__emailform col span-1-of-2">
            <EmailForm />
          </div>
        
          <div className="contact__info col span-1-of-2">
            <div className="contact__info-container">
              {this.address()}
              {this.phone()}
              {this.email()}
              {this.social()}
            </div>
          </div>
        </div>

        <MapContainer />

      </Section>
    )
  }
}

export default Contact