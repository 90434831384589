import React from 'react'

import Section from './Section'
import Card from './Card'

import imgStacie from '../images/team/stacie.jpg'
import imgCarhmi from '../images/team/carhmi.jpg'

const About = () => {
  const stacie = {
    name: "Stacie Wria",
    title: <span>Creative Director<br />Seamstress</span>,
    image: imgStacie 
  }

  const carhmi = {
    name: "Carhmi Shammadah",
    title: <span>Marketing<br />Modelling</span>,
    image: imgCarhmi
  }

  return (
    <Section
      id="about"
      className="about"
      title="About us">
      
      <div className="row">
        <div className="about">
          <div className="about__container">
            <Card {...stacie} />
            <Card {...carhmi} />
          </div>
        </div>
      </div>

    </Section>
  )
}

export default About