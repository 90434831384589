import React from 'react'
  
const Display = ({name, image}) => {
  return (
    <li>
      <figure className="display__img">
        <img
          src={image}
          alt={name} />
      </figure>
    </li>
  )
}

export default Display