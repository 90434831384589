import React from 'react'

import Section from './Section'
import Display from './Display'

import img1 from '../images/showroom/1.jpg'
import img2 from '../images/showroom/2.jpg'
import img3 from '../images/showroom/3.jpg'
import img4 from '../images/showroom/4.jpg'
import img5 from '../images/showroom/5.jpg'
import img6 from '../images/showroom/6.jpg'
import img7 from '../images/showroom/7.jpg'
import img8 from '../images/showroom/8.jpg'

const Showroom = () => {
  return (
    <Section
      id="showroom"
      className="showroom"
      title="Showroom">
      
      <div className="row">
        <div className="showroom">
          <ul className="showroom__container clear-fix">
            <Display image={img1} />
            <Display image={img2} />
            <Display image={img3} />
            <Display image={img4} />
            <Display image={img5} />
            <Display image={img6} />
            <Display image={img7} />
            <Display image={img8} />
          </ul>
        </div>
      </div>

    </Section>
  )
}

export default Showroom