import React from 'react'

import './styles/styles.scss'

import Header from './components/Header'
import Intro from './components/Intro'
import Services from './components/Services'
import Showroom from './components/Showroom'
import About from './components/About'
import Contact from './components/Contact'
import Footer from './components/Footer'

class App extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <Header />
        <Intro />
        <Services />
        <Showroom />
        <About />
        <Contact />
        <Footer />
      </div>
    )
  }
}

export default App