import React from 'react'

import ReactWOW from 'react-wow'

const SectionHeader = ({title, description}) => {
  return (
    <div className="row">
      <ReactWOW animation='bounceInDown' delay="0.4s">
        <h2>{title}</h2>
        {description
          ?  <p className="long-copy">{description}</p>
          : <span></span>}    
      </ReactWOW>
      </div>
    
  )
}

export default SectionHeader